<template>
  <div v-if="sideShow">
    <div class="container" :class="widthChange">
      <!-- 菜单开关 -->
      <!-- <el-button circle @click="open" class="fold">
        <i :class="foldBtnICon"></i>
      </el-button>-->

      <el-row type="flex" justify="left" class="sideNavContainer">
        <el-col :span="24">
          <div class="flex-column-center company">
            <span>{{ $t('companyNameLT') }}</span>
            <div class="verify">
              <img class="verify-img" src="../static/img/identify.png" />
              <span>{{ $t('certified') }}</span>
            </div>
            <img class="verify-img" src="../static/img/company-img.png" />
          </div>
          <el-menu
            @select="handleSelect"
            :collapse="isCollapse"
            active-text-color="#81D8D0"
            :router="true"
            :default-active="'/' + this.$route.path.split('/')[1]"
          >
            <el-menu-item v-for="route in pathList" :key="route.path" :index="route.path">
              <i :class="route.icon"></i>
              <span slot="title">{{ $t(route.title) }}</span>
            </el-menu-item>
          </el-menu>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isCollapse: false,
      widthChange: 240,
      foldBtnICon: 'el-icon-s-fold',
      pathList: [
        {
          icon: 'el-icon-menu',
          path: '/supply-all',
          title: 'adnavPage.overview'
        },
        {
          icon: 'el-icon-s-claim',
          path: '/receivable-list',
          title: 'adnavPage.receivable'
        },
        {
          icon: 'el-icon-bangzhu',
          path: '/financing-list',
          title: 'adnavPage.financingManage'
        },
        {
          icon: 'el-icon-s-ticket',
          path: '/repayment-list',
          title: 'adnavPage.repaymentManage'
        },
        {
          icon: 'el-icon-s-operation',
          path: '/supply-list',
          title: 'adnavPage.supplyManage'
        }
        // }, {
        //   icon: 'el-icon-office-building',
        //   // path: '/contract-list',
        //   title: 'adnavPage.contractManage'
        // }]
      ]
    };
  },
  created() {
    // console.log(this.$store.getters['auth/isSide']);
    // this.isCollapse = this.$store.getters['auth/isSide'];
  },
  methods: {
    handleSelect(key, keyPath) { },
    open() {
      this.isCollapse = !this.isCollapse;
      this.widthChange =
        this.widthChange === 'menu-close' ? 'menu-open' : 'menu-close';
      this.foldBtnICon =
        this.foldBtnICon === 'el-icon-s-fold'
          ? 'el-icon-s-unfold'
          : 'el-icon-s-fold';
    }
  },
  computed: {
    sideShow() {
      return this.$store.getters['auth/isSide'];
    }
  }
};
</script>

<style lang="scss" scoped>
.navbg {
  background-color: #f9fafc;
}
.container {
  background-color: #f7f7f7;
  width: 240px;
  // .el-menu-item:hover {
  //   outline: 0 !important;
  //   background: #fff3e0 !important;
  // }
  // .el-menu-item.is-active {
  //   background: #fff !important;
  // }
}
.company {
  height: 320px;
  background: #353e46;
  color: white;
  .verify {
    color: #81d8d0;
    margin: 20px 0;
    .verify-img {
      position: relative;
      top: 2px;
      left: -6px;
    }
  }
}
.menu-close {
  animation: menuClose 500ms 1;
  animation-fill-mode: forwards;
}
.menu-open {
  animation: menuOpen 500ms 1;
  animation-fill-mode: forwards;
}
.fold {
  position: absolute;
  margin-top: 10px;
  background-color: #454545;
  border: 0;
  z-index: 99;
  right: 25px;
  width: 25px;
  height: 25px;
  .el-icon-s-fold,
  .el-icon-s-unfold {
    position: absolute;
    left: 5px;
    top: 5px;
  }
}
@keyframes menuClose {
  from {
    width: 240px;
  }
  to {
    width: 70px;
  }
}
@keyframes menuOpen {
  from {
    width: 70px;
  }
  to {
    width: 240px;
  }
}
</style>
