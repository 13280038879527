<template>
  <div>
    <div class="flex-row navbar">
      <img class="logo" src="../static/img/logo.png" @click="$router.push('/')" style="cursor: pointer;" />
      <span class="navBarTitle" @click="$router.push('/')" style="cursor: pointer;">{{ $t("navbar.title") }}</span>
      <span class="navBarSubTitle" @click="$router.push('/')" style="cursor: pointer;">{{ $t("navbar.subTitle") }}</span>
      <div class="flex-wrap"></div>
      <el-dropdown @command="langeSwitch">
        <span class="el-dropdown-link">{{ lanage }}</span>
        <i class="el-icon-arrow-down el-icon--right"></i>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item :command="$t('navbar.langueEn')">{{
            $t("navbar.langueEn")
          }}</el-dropdown-item>
          <el-dropdown-item :command="$t('navbar.langueCn')">{{
            $t("navbar.langueCn")
          }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <!-- <el-dropdown @command="menuSwich">
        <el-avatar icon="el-icon-user-solid"></el-avatar>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="logout">{{ $t('navbar.logout') }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>-->
      <el-button size="small" class="btn-out" @click="loginOut()">{{
        $t("navbar.tuiLohout")
      }}</el-button>
    </div>
  </div>
</template>

<script>
import Storage from '../utils/storage.js';
export default {
  data() {
    return {
      lanage: ''
    };
  },
  mounted() {
    this.lanage = Storage.getLocalItem('i18n-admin') === 'zh-cn' ? '中文' : 'English';
  },
  methods: {
    langeSwitch(command) {
      // this.$message({ message: this.$t('navbar.changeLangePrompt') + command, type: 'success' });
      let local = command === '中文' ? 'zh-cn' : 'zh-en';
      Storage.setLocalItem('i18n-admin', local);
      this.$i18n.locale = local;
      this.lanage = command;
      this.$store.dispatch('auth/language', local);
      window.location.reload();
    },
    menuSwich(command) {
      console.log(command);
      switch (command) {
        case 'person':

          break;
        case 'manage':

          break;
        case 'logout':
          this.$store.dispatch('auth/logoutSuccess', {
            username: '',
            authorization: ''
          });
          history(0);
          this.$router.push({ path: '/register' });

          break;
        default:
          break;
      }
    },
    loginOut() {
      this.$store.dispatch('auth/logoutSuccess', undefined);
      this.$router.push({ path: '/register' });
    }

  }
};
</script>

<style lang="scss" scoped>
.navbar {
  height: 60px;
  width: 100vw;
  min-width: 1200px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  .logo {
    width: 38px;
    height: 30px;
    margin-left: 40px;
  }
  .navBarTitle {
    margin: 0 20px;
    margin-right: 10px;
    font-size: 30px;
    font-weight: bolder;
  }
  .navBarSubTitle {
    font-size: 24px;
  }
  .el-dropdown {
    margin: 0 10px;
    .el-avatar {
      margin-top: 10px;
      margin-right: 30px;
    }
  }
  .obgTitle {
    display: inline-block;
    font-size: 26px;
    font-weight: 600;
  }
  .btn-out {
    margin-right: 30px;
    border-color: #81d8d0;
  }
}
</style>
